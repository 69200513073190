import { useTranslation } from 'src/i18n';

export default function useLanguageDisplayLabel() {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const getLanguageLabel = language => {
    let displayLabel = language?.display_name;
    const translatedName = t(language?.translation_name);

    if (displayLabel?.toLocaleLowerCase() !== translatedName.toLocaleLowerCase()) {
      displayLabel += ` (${translatedName})`;
    }

    return displayLabel;
  };

  return getLanguageLabel;
}
