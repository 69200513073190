/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useMemo, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import Drawer from 'components/uiLibrary/Drawer';
import Loader from 'components/uiLibrary/Loader';

import { primaryLanguageCodes, allLanguageCodes } from 'constants/languageCodes';

import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';
import { LANGUAGE_PREFERRED, EXPIRY_TIME } from 'constants/cookieConstants';
import { setCookie } from 'utils/cookie';

import usePageContext from 'utils/hooks/usePageContext';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS } from 'utils/tracking';
import useLanguageDisplayLabel from 'utils/hooks/useLanguageDisplayLabel';

import classes from './LanguageSelector.module.scss';

const LanguageOption = ({ getUpdatedPath, language, isSelected, onClick }) => {
  const url = getUpdatedPath(language?.iso);
  const getLanguageLabel = useLanguageDisplayLabel();

  return (
    <a
      className={classnames(classes.languageOption, { [classes.languageOption_disabled]: isSelected })}
      href={url}
      onClick={() => onClick(language?.iso)}
    >
      <span className={classes.languageOption__radioWrapper}>
        <input className={classes.languageOption__radioInput} type="radio" checked={isSelected} />
      </span>
      <Typography
        className={classes.languageOption__label}
        weight={isSelected ? 'bold' : 'regular'}
        color={isSelected ? 'link' : 'primary'}
      >
        {getLanguageLabel(language)}
      </Typography>
    </a>
  );
};

const GroupedLanguages = ({ title, languages, currentLanguage, getUpdatedPath, onChange }) => (
  <div>
    <div className={classnames(classes.title)}>
      <Typography size={14} weight="bold">
        {title}
      </Typography>
    </div>
    <ul className={classnames(classes.options)}>
      {languages.map(language => (
        <LanguageOption
          key={language.iso}
          language={language}
          getUpdatedPath={getUpdatedPath}
          isSelected={language?.iso === currentLanguage?.iso}
          onClick={onChange}
        />
      ))}
    </ul>
  </div>
);

const LanguageList = ({ currentLanguage, onChange }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { pathname, navigate } = usePageContext();
  const [path, query] = useMemo(
    () =>
      navigate
        .removeLocaleFromPath(pathname)
        ?.replace(/\/$/, '')
        ?.split('?'),
    [navigate, pathname],
  );

  const getUpdatedPath = useCallback(language => `${path}/${language}${query ? `?${query}` : ''}`, [path, query]);

  return (
    <>
      <GroupedLanguages
        title={t(`${TP}.FN_SUGGESTED_LANGUAGE`)}
        languages={primaryLanguageCodes}
        currentLanguage={currentLanguage}
        getUpdatedPath={getUpdatedPath}
        onChange={onChange}
      />
      <div className={classes.separator}></div>
      <GroupedLanguages
        title={t(`${TP}.FN_FILTER_ALL`)}
        languages={allLanguageCodes}
        currentLanguage={currentLanguage}
        getUpdatedPath={getUpdatedPath}
        onChange={onChange}
      />
    </>
  );
};

const LoaderWrapper = ({ isLoading }) => (
  <div className={classes.globalLoader}>
    <Loader isLoading={isLoading} />
  </div>
);

const LanguageSelector = ({ forFooter = false, renderAs = 'popUp', isProfileDropdown }) => {
  const ref = useRef();
  const track = useNewTracking();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:960px)');
  const { language } = usePageContext();

  const { t } = useTranslation('NS_APP_GLOBALS');
  const getLanguageLabel = useLanguageDisplayLabel();

  const currentLanguage = useMemo(() => allLanguageCodes.find(lang => lang.iso === language), [language]);

  const handleLanguageSelect = langIso => {
    setCookie(LANGUAGE_PREFERRED, langIso, new Date(new Date().getTime() + EXPIRY_TIME.YEAR));
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: isProfileDropdown
            ? PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_SELECT_LANGUAGE
            : TRACK_EVENTS.SELECT_LANGUAGE,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
    track.click({
      section: SECTIONS.LANGUAGE_SELECTOR,
      component: COMPONENTS.LANGUAGE,
      meta: { langIso },
    });

    setLoading(true);
  };

  useOnClickOutside(ref, () => {
    if (!isMobile) {
      setOpen(false);
    }
  });

  if (renderAs !== 'popUp') {
    return (
      <>
        {forFooter && (
          <div className={classnames(classes.languageList__header)}>
            <Typography size={16} weight="bold">
              {t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)}
            </Typography>
          </div>
        )}
        <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} />
        {isLoading && <LoaderWrapper isLoading={isLoading} />}
      </>
    );
  }

  return (
    <div className={classes.root} ref={ref} aria-label="Languages list">
      <div
        className={classnames(classes.selector, {
          [classes.selector_forFooter]: forFooter,
          [classes.isSelected]: isOpen,
        })}
        onClick={() => setOpen(!isOpen)}
      >
        <SpriteIcon icon="world" className={classes.worldIcon} />
        <span className={classes.current}>{forFooter ? getLanguageLabel(currentLanguage) : currentLanguage?.iso}</span>
        <SpriteIcon icon="arrow_drop_down" />
      </div>

      {!isMobile && isOpen && (
        <div
          className={classnames(classes.languageList, {
            [classes.languageList_forFooter]: forFooter,
          })}
        >
          <div className={classnames(classes.languageList__header)}>
            <Typography size={16} weight="bold">
              {t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)}
            </Typography>
          </div>
          <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} />
          {isLoading && <LoaderWrapper isLoading={isLoading} />}
        </div>
      )}
      {isMobile && isOpen && (
        <Drawer isOpen={isOpen} title={t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)} onClose={() => setOpen(false)}>
          <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} />
          {isLoading && <LoaderWrapper isLoading={isLoading} />}
        </Drawer>
      )}
    </div>
  );
};

export default LanguageSelector;
